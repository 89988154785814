import { ReactNode } from 'react'

import { useController, useFormContext } from 'react-hook-form'
import { GoPay } from '@sga/shopify-payments-types'

import { Card, ChoiceList, Link, Select, SelectOption, Button, Stack, TextContainer } from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { GATEWAY_TYPE } from '../util/constants'

export const GATEWAY_TYPE_KEY = 'gatewayType';

type GoPayDefaultInstrumentProps = {
  gatewayType: string;
  setGatewayType: React.Dispatch<React.SetStateAction<string>>;
};

export function GoPayDefaultInstrument({ gatewayType, setGatewayType }: GoPayDefaultInstrumentProps) {
  const account = useAccount<GoPay.TSegmentAccount>()
  const { appType } = useAuth()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'defaultPaymentInstrument',
    defaultValue: account.segmentConfig.defaultPaymentInstrument || '',
  })

  function handleGatewayTypeChange (selected: string) {
    setGatewayType(selected);
    window.localStorage.setItem(GATEWAY_TYPE_KEY, selected);
  };

  function GoPayDefaultView () {
    return (
      <>
        <Select
          label={getTranslation('Preferovaný typ brány', 'Preferred gateway type')}
          value={gatewayType}
          options={getGatewayTypes()}
          onChange={handleGatewayTypeChange}
          placeholder={getTranslation('Vyberte typ brány...', 'Select a gateway type...')}
        />
        {
          gatewayType === GATEWAY_TYPE.SINGLE ? (
            <>
              <br />
              <ChoiceList
                selected={[field.value]}
                title={getTranslation('Preferovaná platební metoda', 'Preferred payment method')}
                choices={getInstruments().map(({ label, value, help, helpPath }) => ({
                  label,
                  value,
                  helpText: help || (
                    <Link external url={`https://www.gopay.com/${helpPath}`}>
                      {getTranslation('Další informace', 'More information')}
                    </Link>
                  ),
                }))}
                onChange={(selected) =>
                  setValue(field.name, selected[0], {
                    shouldDirty: true,
                    shouldTouch: true,
                  })
                }
              />
            </>
          ) : gatewayType === GATEWAY_TYPE.MULTIPLE ? (
            <>
              <br />
              <AppSectionView appInfo={appInfo().gopayCard}/>
              <br />
              <AppSectionView appInfo={appInfo().gopayBank}/>
            </>
          ) : null
        }
      </>
    )
  }

  function GoPayAppView ({ appAInfo, appBInfo}: { appAInfo: PaymentAppInfo, appBInfo: PaymentAppInfo }) {
    return (
      <>
        <TextContainer>
          GoPay Payment Options
        </TextContainer>
        <br />
        <AppSectionView appInfo={appAInfo}/>
        <br />
        <AppSectionView appInfo={appBInfo}/>
      </>
    ) 
  }

  function AppSectionView ({ appInfo }: { appInfo: PaymentAppInfo }) {
    function handleOpenApp (appLink: string) {
      window.open(appLink, '_blank', 'noopener,noreferrer');
    };

    return (
      <Stack distribution="equalSpacing" alignment='center'>
        <TextContainer>
          {appInfo.name}
          <p>
            <Link external url={`https://www.gopay.com/${appInfo.helpPath}`}>
              {getTranslation('Další informace', 'More information')}
            </Link>
          </p>
        </TextContainer>
        <Button onClick={() => handleOpenApp(appInfo.appLink)}>{getTranslation('OTEVŘENO', 'Open')}</Button>
      </Stack>
    )
  }

  function selectView () {
    switch (appType) {
      case 'PAYMENT_CARD':
        return <GoPayAppView appAInfo={appInfo().gopay} appBInfo={appInfo().gopayCard}/>
      case 'BANK_ACCOUNT':
        return <GoPayAppView appAInfo={appInfo().gopay} appBInfo={appInfo().gopayBank}/>
      default:
        return <GoPayDefaultView />
    }
  }

  return (
    <Card.Section>
      {selectView()}
    </Card.Section>
  )
}

function getGatewayTypes(): SelectOption[] {
  return [
    { value: 'SINGLE', label: getTranslation('Jediná brána', 'Single gateway') },
    { value: 'MULTIPLE', label: getTranslation('Vícenásobná brána', 'Multiple gateway') },
  ]
}

function appInfo() {
  return {
    gopay: {
      name: getTranslation('Platby GoPay', 'GoPay payments'),
      appLink: 'https://google.com',
      helpPath: getTranslation('cs', 'en')
    },
    gopayCard: {
      name: getTranslation('Platby kartou GoPay', 'GoPay card payments'),
      appLink: 'https://google.com',
      helpPath: getTranslation('cs/online-platba-kartou', 'en/online-card-payments')
    },
    gopayBank: {
      name: getTranslation('Bankovní platby GoPay', 'GoPay bank payments'),
      appLink: 'https://google.com',
      helpPath: getTranslation('cs/online-bankovni-prevod', 'en/online-bank-tranfer')
    }
  }
}

type PaymentAppInfo = {
  name: string;
  appLink: string;
  helpPath: string;
}

type TInstrument = {
  value: GoPay.TPaymentInstrument
  label: string
  helpPath?: string
  help?: ReactNode
}

function getInstruments(): ReadonlyArray<TInstrument> {
  return [
    {
      value: '' as any,
      label: getTranslation('Zákazník si vybere', 'Customer will choose'),
      help: (
        <span>
          {getTranslation(
            `Můžete vybrat platební metodu, kterou chcete aby zákazník využíval nejčastěji.
             I přes toto nastavení si však zákazník může vybrat jinou (z vámi povolených) možností.`,
            `You can choose payment method that you want the customer used it most often.
            However, despite this setting, the customer can choose another option (from the ones you allow)
            `,
          )}
        </span>
      ),
    },
    {
      value: 'PAYMENT_CARD',
      label: getTranslation('Platba kartou', 'Card payment'),
      helpPath: getTranslation('cs/online-platba-kartou', 'en/online-card-payments'),
    },
    {
      value: 'BANK_ACCOUNT',
      label: getTranslation('Bankovní převod', 'Bank transfer'),
      helpPath: getTranslation('cs/online-bankovni-prevod', 'en/online-bank-tranfer'),
    },

    // {
    //   value: 'GPAY',
    //   label: 'Google pay',
    //   helpPath: getTranslation('cs/google-pay', 'en/google-pay'),
    // },

    // {
    //   value: 'APPLE_PAY',
    //   label: 'Apple pay',
    //   helpPath: getTranslation('cs/apple-pay', 'en/apple-pay'),
    // },

    // {
    //   value: 'PAYPAL',
    //   label: 'Paypal',
    //   help: (
    //     <span>
    //       {getTranslation(
    //         'Pro aktivaci PayPal následujte',
    //         'For PayPal activation follow',
    //       )}{' '}
    //       <Link
    //         url={getTranslation(
    //           'https://help.gopay.com/cs/tema/mam-platebni-branu/chci-pouzivat-gopay-obchodni-ucet/nastaveni-platebnich-metod/jak-aktivuji-paypal',
    //           'https://help.gopay.com/en/knowledge-base/gopay-account/gopay-business-account/settings-of-payment-methods/how-do-i-activate-the-paypal-payment-method',
    //         )}
    //         external
    //       >
    //         {getTranslation('tento návod', 'this manual')}
    //       </Link>
    //     </span>
    //   ),
    // },
  ]
}
