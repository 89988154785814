import { FormProvider } from 'react-hook-form'

import { Button, Card, Form, SelectOption, Stack, Toast } from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { SettingsLanguage } from './SettingsLanguage'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'
import { TAccountActivationStatus } from '@sga/shopify-payments-types'

type TProps = {
  goBack: () => any
  status: TAccountActivationStatus
}
GPWebPayAccountSettings.segment = segment('gpwebpay')

export function GPWebPayAccountSettings({ goBack, status }: TProps) {
  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm((api, formValues) => api.saveConfig(formValues))

  if (status === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          <SettingsLanguage languages={getLanguages()} />
          <SettingsPaymentLabel />
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Button onClick={goBack}>{getTranslation('Zpět', 'Go Back')}</Button>
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}

function getLanguages(): SelectOption[] {
  return [
    { value: '' as any, label: getTranslation('Automaticky', 'Automatically') },
    { value: 'cs', label: getTranslation('Čeština', 'Czech') },
    { value: 'sk', label: getTranslation('Slovenština', 'Slovak') },
    { value: 'en', label: getTranslation('Angličtina', 'English') },
    { value: 'ar', label: getTranslation('Arabština', 'Arabic') },
    { value: 'da', label: getTranslation('Dánština', 'Danish') },
    { value: 'fi', label: getTranslation('Finština', 'Finnish') },
    { value: 'fr', label: getTranslation('Francouzština', 'French') },
    { value: 'nl', label: getTranslation('Nizozemština', 'Dutch') },
    { value: 'it', label: getTranslation('Italština', 'Italian') },
    { value: 'lv', label: getTranslation('Lotyština', 'Latvian') },
    { value: 'hu', label: getTranslation('Maďarština', 'Hungarian') },
    { value: 'de', label: getTranslation('Němčina', 'German') },
    { value: 'no', label: getTranslation('Norština', 'Norwegian') },
    { value: 'pl', label: getTranslation('Polština', 'Polish') },
    { value: 'pt', label: getTranslation('Portugalština', 'Portuguese') },
    { value: 'ru', label: getTranslation('Ruština', 'Russian') },
    { value: 'es', label: getTranslation('Španělština', 'Spanish') },
    { value: 'sv', label: getTranslation('Švédština', 'Swedish') },
    { value: 'uk', label: getTranslation('Ukrajinština', 'Ukrainian') }
  ];
}
